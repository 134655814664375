@use "import/var";
@use "import/mixin_common" as common;

.js-sticky_schedule {
	z-index: 100;
	position: absolute;
	top: 0;
	width: 100%;

	&._fixed {
		position: fixed;

		@media #{var.$pc} {
			top: var.$pc_header_height;
			width: calc(100vw - 60px - 30px - 280px - 2px);
			max-width: 858px;
		}

		@media #{var.$sp} {
			top: var.$sp_header_height;
			left: 0;
			// width: calc(100vw - var.$sp_padding * 2 - 2px);
		}
	}
}

.schedule_content {
	$pc_schedule_head: 90px;
	$sp_schedule_head: 65px;
	$sp_schedule_moth_height: 50px;
	$sp_schedule_head_no_pdf: 65px;
	$xs_schedule_height: 50px;

	border-radius: 15px;
	border: 1px solid #dfe5ed;
	position: relative;
	top: 0;
	left: 0;

	@media #{var.$ipc} {
		margin-top: 9%;
		padding-top: $pc_schedule_head;

		&__head {
			height: $pc_schedule_head;
		}
	}

	@media #{var.$isp} {
		@include common.sp_unindent;

		margin-top: $sp_schedule_moth_height + 30px;
		padding-top: $sp_schedule_head;

		&__head {
			height: $sp_schedule_head;
		}

		// &._no_pdf {
		// 	padding-top: $sp_schedule_head_no_pdf;

		// 	.schedule_content__head {
		// 		height: $sp_schedule_head_no_pdf;
		// 	}
		// }
	}

	@media #{var.$xs} {
		padding-top: $xs_schedule_height;

		&__head {
			height: $xs_schedule_height;
		}
	}

	@media #{var.$ipc} {
		overflow: hidden;
	}

	@media #{var.$isp} {
		border-left: 0 none;
		border-right: 0 none;
		border-radius: 0;
	}

	&__head {
		background: linear-gradient(to bottom, #eff3f5 0%, #ecf6e7 100%);
		box-sizing: border-box;
		width: 100%;
		padding-left: 2.2vw;
		padding-right: 2.2vw;
		border-bottom: 1px solid #dfe5ed;

		@media #{var.$ipc} {
			display: flex;
			align-items: flex-end;
		}

		@media #{var.$isp} {
			padding-top: 9px;
			padding-left: 10px;
			padding-right: 10px;
		}

		@media #{var.$xs} {
			padding-top: 5px;
		}

		@media #{var.$pc} {
			height: $pc_schedule_head;
			box-sizing: border-box;
		}

		@media screen and (max-width: 1200px) and (min-width: 1000px) {
			height: 80px;
		}

		.month_area {
			@media #{var.$ipc} {
				flex-grow: 0;
				flex-basis: 26%;
				flex-shrink: 0;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				text-align: center;
				height: 100%;
				padding-left: 1.7em;
				box-sizing: border-box;
			}

			@media #{var.$isp} {
				position: absolute;
				height: $sp_schedule_moth_height;
				top: -$sp_schedule_moth_height;
				left: 0;
				width: 100%;
				text-align: center;
			}

			@media #{var.$xs} {
				height: $xs_schedule_height;
				top: -$xs_schedule_height;
			}
		}

		.month {
			color: #1b2a46;
			line-height: 1;

			.num {
				letter-spacing: -0.1em;
				line-height: 1;

				@media #{var.$pc} {
					font-size: 50px;
				}

				@media #{var.$sp} {
					font-size: 40px;
				}
			}

			.unit {
				font-size: 25px;
				font-weight: bold;
				padding-left: 0.3em;
			}
		}

		.tab_area {
			flex-basis: 74%;
			// padding-top: 28px;
			height: 55px;

			@media #{var.$xs} {
				height: $xs_schedule_height - 10px;
			}
		}

		.tab_list {
			display: flex;
			box-sizing: border-box;
			position: relative;
			top: 1px;
			left: 0;

			@media #{var.$ipc} {
				margin-left: -5px;
				margin-right: -5px;
			}

			@media #{var.$sp} {
				justify-content: center;
			}

			@media #{var.$isp} {
				margin-left: -3px;
				margin-right: -3px;
			}

			@media #{var.$xs} {
				padding-bottom: 5px;
				margin-left: -1px;
				margin-right: -1px;
			}

			> li {
				width: 16.66%;
				box-sizing: border-box;

				@media #{var.$ipc} {
					padding-left: 5px;
					padding-right: 5px;
					max-width: 140px;
				}

				@media #{var.$isp} {
					padding-left: 3px;
					padding-right: 3px;
					max-width: 80px;
				}

				@media #{var.$xs} {
					padding-left: 1px;
					padding-right: 1px;
				}
			}

			.tab {
				box-sizing: border-box;
				color: #ffffff;
				text-decoration: none;
				border-top-left-radius: 6px;
				border-top-right-radius: 6px;
				width: 100%;
				background-color: #439574;
				height: 55px;
				max-width: 130px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				transition: all 0.2s var.$easeOutQuint;

				@media #{var.$xs} {
					height: $xs_schedule_height - 6px;
				}

				&:hover {
					background-color: darken(#439574, 20);
				}

				.week {
					font-weight: bold;
					font-size: var.$fontsize20;

					@media #{var.$isp} {
						line-height: 1;
						font-size: var.$fontsize16;
						padding-top: 0.05em;
						padding-bottom: 0.1em;
					}
				}

				&._selected {
					background-color: #ffffff;
					color: #293751;
					position: relative;
					top: 0;
					left: 0;
					border: 1px solid #dfe5ed;
					border-bottom: 0 none;
					overflow: hidden;
					pointer-events: none;

					&::before {
						content: "";
						background-color: #1b2a46;
						height: 4px;
						width: 100%;
						position: absolute;
						top: 0;
						left: 0;
					}

					&:hover {
						background-color: #ffffff;
					}
				}
			}
		}
	}

	&__body {
		@media #{var.$ipc} {
			padding: 30px;
		}

		@media #{var.$isp} {
			padding: 30px var.$sp_padding;
		}
	}
}

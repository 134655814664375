@use "import/var";
@use "import/mixin_common" as common;

.schedule_person_list {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	margin: -5px;

	> li {
		margin-top: 5px;
		margin-bottom: 5px;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		padding-left: 5px;
		padding-right: 5px;

		@media #{var.$pc} {
			min-width: 33.33%;
		}

		@media #{var.$sp} {
			min-width: 0;
		}
	}
}

.schedule_person {
	min-height: 70px;
	background-color: #ffffff;
	color: #111111;
	text-decoration: none;
	align-items: center;
	line-height: 1.3;
	transition: background-color 0.2s var.$easeOutQuint;
	border-radius: 6px;
	// background-color: #ffffff;
	display: flex;
	justify-content: flex-start;
	box-sizing: border-box;

	@media #{var.$ipc} {
		padding: 5px 10px;
	}

	@media #{var.$isp} {
		padding: 5px;
	}

	@media #{var.$xs} {
		// width: 100%;
	}

	&::before {
		@include common.bg-block("../img/icon/schedule_male.webp", 60px, 62px);

		margin-right: 10px;
		flex-basis: 46px;
		flex-shrink: 0;
		flex-grow: 0;
		background-position: center center;
		background-size: contain;

		@media #{var.$isp} {
			width: 40px;
			height: 40px;
			flex-basis: 40px;
		}
	}

	&::after {
		@include common.bg-block("../img/icon/arrow/schedule_male.webp", 24px, 24px);

		margin-left: 10px;
		min-width: 24px;
		flex-basis: 24px;
	}

	&._male {
		&::before {
			background-image: url("../img/icon/schedule_male.webp");
		}

		&::after {
			background-image: url("../img/icon/arrow/schedule_male.webp");
		}
	}

	&._female {
		&::before {
			background-image: url("../img/icon/schedule_female.webp");
		}

		&::after {
			background-image: url("../img/icon/arrow/schedule_female.webp");
		}
	}

	.name {
		font-size: var.$fontsize18;
	}

	.option {
		font-size: var.$fontsize15;
		line-height: 1.3;
		margin-top: 0.2em;
	}

	.label {
		margin-top: 0.2em;

		&._appointment_only {
			font-size: var.$fontsize14;
			background-color: #de617b;
			color: #ffffff;
			display: inline-flex;
			justify-content: center;
			align-items: center;
			border-radius: 5px;
			padding: 0.3em 0.8em;
			min-width: 60px;
			min-height: 24px;
			line-height: 1;
			box-sizing: border-box;
		}
	}

	&._noperson {
		&::before,
		&::after {
			display: none;
		}
	}
}

a.schedule_person {
	&:hover {
		background: rgba(#b7d3d0, 0.2);
	}

	&._disabled {
		pointer-events: none;

		&::after {
			display: none;
		}
	}
}

@charset "UTF-8";
/* stylelint-disable at-rule-empty-line-before */
/* simple clearfix */
.dep_pagelink_area {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .dep_pagelink_area > ul {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
  }
  .dep_pagelink_area > ul > li {
    display: flex;
    width: calc(50% - 10px);
  }
}
@media screen and (max-width: 767px) {
  .dep_pagelink_area > ul > li {
    margin-bottom: 2em;
  }
}

.dep_pagelink {
  width: 100%;
  font-size: 1rem;
  text-decoration: none;
  color: #111111;
  background-color: #ffffff;
  border: 1px solid #dfe5ed;
  border-radius: 35px;
  line-height: 1.3;
  box-sizing: border-box;
  position: relative;
  transition: all 0.2s cubic-bezier(0.23, 1, 0.32, 1);
  height: 70px;
  min-width: 220px;
  overflow: hidden;
}
.dep_pagelink__head {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  height: 70px;
  padding-left: 30px;
}
.dep_pagelink__head__l {
  padding: 23px 0;
}
.dep_pagelink__head__r {
  cursor: pointer;
  width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #439574;
  position: relative;
}
.dep_pagelink__head__r::before {
  content: "";
  display: block;
  background-color: #dfe5ed;
  position: absolute;
  left: 0;
  height: 40px;
  width: 1px;
}
.dep_pagelink__head__r .toggle::before {
  content: "＋";
  font-size: 24px;
}
.dep_pagelink__head__r .toggle._open::before {
  content: "−";
  display: block;
  font-size: 30px;
}
.dep_pagelink__head .cb {
  width: 0;
  height: 0;
  overflow: hidden;
}
.dep_pagelink__head::after {
  content: "";
  display: block;
  background-color: #dfe5ed;
  position: absolute;
  top: 71px;
  height: 1px;
  width: 100%;
}
.dep_pagelink__body {
  overflow: auto;
  will-change: transform;
  top: 70px;
  position: absolute;
  width: 100%;
  height: 260px;
  box-sizing: border-box;
  padding-top: 20px;
}
.dep_pagelink a {
  text-decoration: none;
  color: #111111;
  font-size: 1rem;
  padding: 0.8em 30px 0.8em 70px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
  overflow: hidden;
}
.dep_pagelink a::before {
  content: "";
  display: block;
  background: url("../img/icon/arrow/circle_green_24.webp") 0 0 no-repeat transparent;
  background-size: 24px 24px;
  width: 24px;
  height: 24px;
  transform: rotate(90deg);
  position: absolute;
  left: 30px;
}
.dep_pagelink a:hover {
  color: #59a400;
}

.js-sticky_schedule {
  z-index: 100;
  position: absolute;
  top: 0;
  width: 100%;
}
.js-sticky_schedule._fixed {
  position: fixed;
}
@media screen and (min-width: 1000px), print {
  .js-sticky_schedule._fixed {
    top: 120px;
    width: calc(100vw - 60px - 30px - 280px - 2px);
    max-width: 858px;
  }
}
@media screen and (max-width: 999px) {
  .js-sticky_schedule._fixed {
    top: 60px;
    left: 0;
  }
}

.schedule_content {
  border-radius: 15px;
  border: 1px solid #dfe5ed;
  position: relative;
  top: 0;
  left: 0;
}
@media screen and (min-width: 768px) {
  .schedule_content {
    margin-top: 9%;
    padding-top: 90px;
  }
  .schedule_content__head {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .schedule_content {
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 80px;
    padding-top: 65px;
  }
  .schedule_content__head {
    height: 65px;
  }
}
@media screen and (max-width: 575px) {
  .schedule_content {
    padding-top: 50px;
  }
  .schedule_content__head {
    height: 50px;
  }
}
@media screen and (min-width: 768px) {
  .schedule_content {
    overflow: hidden;
  }
}
@media screen and (max-width: 767px) {
  .schedule_content {
    border-left: 0 none;
    border-right: 0 none;
    border-radius: 0;
  }
}
.schedule_content__head {
  background: linear-gradient(to bottom, #eff3f5 0%, #ecf6e7 100%);
  box-sizing: border-box;
  width: 100%;
  padding-left: 2.2vw;
  padding-right: 2.2vw;
  border-bottom: 1px solid #dfe5ed;
}
@media screen and (min-width: 768px) {
  .schedule_content__head {
    display: flex;
    align-items: flex-end;
  }
}
@media screen and (max-width: 767px) {
  .schedule_content__head {
    padding-top: 9px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media screen and (max-width: 575px) {
  .schedule_content__head {
    padding-top: 5px;
  }
}
@media screen and (min-width: 1000px), print {
  .schedule_content__head {
    height: 90px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 1200px) and (min-width: 1000px) {
  .schedule_content__head {
    height: 80px;
  }
}
@media screen and (min-width: 768px) {
  .schedule_content__head .month_area {
    flex-grow: 0;
    flex-basis: 26%;
    flex-shrink: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    height: 100%;
    padding-left: 1.7em;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 767px) {
  .schedule_content__head .month_area {
    position: absolute;
    height: 50px;
    top: -50px;
    left: 0;
    width: 100%;
    text-align: center;
  }
}
@media screen and (max-width: 575px) {
  .schedule_content__head .month_area {
    height: 50px;
    top: -50px;
  }
}
.schedule_content__head .month {
  color: #1b2a46;
  line-height: 1;
}
.schedule_content__head .month .num {
  letter-spacing: -0.1em;
  line-height: 1;
}
@media screen and (min-width: 1000px), print {
  .schedule_content__head .month .num {
    font-size: 50px;
  }
}
@media screen and (max-width: 999px) {
  .schedule_content__head .month .num {
    font-size: 40px;
  }
}
.schedule_content__head .month .unit {
  font-size: 25px;
  font-weight: bold;
  padding-left: 0.3em;
}
.schedule_content__head .tab_area {
  flex-basis: 74%;
  height: 55px;
}
@media screen and (max-width: 575px) {
  .schedule_content__head .tab_area {
    height: 40px;
  }
}
.schedule_content__head .tab_list {
  display: flex;
  box-sizing: border-box;
  position: relative;
  top: 1px;
  left: 0;
}
@media screen and (min-width: 768px) {
  .schedule_content__head .tab_list {
    margin-left: -5px;
    margin-right: -5px;
  }
}
@media screen and (max-width: 999px) {
  .schedule_content__head .tab_list {
    justify-content: center;
  }
}
@media screen and (max-width: 767px) {
  .schedule_content__head .tab_list {
    margin-left: -3px;
    margin-right: -3px;
  }
}
@media screen and (max-width: 575px) {
  .schedule_content__head .tab_list {
    padding-bottom: 5px;
    margin-left: -1px;
    margin-right: -1px;
  }
}
.schedule_content__head .tab_list > li {
  width: 16.66%;
  box-sizing: border-box;
}
@media screen and (min-width: 768px) {
  .schedule_content__head .tab_list > li {
    padding-left: 5px;
    padding-right: 5px;
    max-width: 140px;
  }
}
@media screen and (max-width: 767px) {
  .schedule_content__head .tab_list > li {
    padding-left: 3px;
    padding-right: 3px;
    max-width: 80px;
  }
}
@media screen and (max-width: 575px) {
  .schedule_content__head .tab_list > li {
    padding-left: 1px;
    padding-right: 1px;
  }
}
.schedule_content__head .tab_list .tab {
  box-sizing: border-box;
  color: #ffffff;
  text-decoration: none;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  width: 100%;
  background-color: #439574;
  height: 55px;
  max-width: 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.2s cubic-bezier(0.23, 1, 0.32, 1);
}
@media screen and (max-width: 575px) {
  .schedule_content__head .tab_list .tab {
    height: 44px;
  }
}
.schedule_content__head .tab_list .tab:hover {
  background-color: #234f3d;
}
.schedule_content__head .tab_list .tab .week {
  font-weight: bold;
  font-size: 1.25rem;
}
@media screen and (max-width: 767px) {
  .schedule_content__head .tab_list .tab .week {
    line-height: 1;
    font-size: 1rem;
    padding-top: 0.05em;
    padding-bottom: 0.1em;
  }
}
.schedule_content__head .tab_list .tab._selected {
  background-color: #ffffff;
  color: #293751;
  position: relative;
  top: 0;
  left: 0;
  border: 1px solid #dfe5ed;
  border-bottom: 0 none;
  overflow: hidden;
  pointer-events: none;
}
.schedule_content__head .tab_list .tab._selected::before {
  content: "";
  background-color: #1b2a46;
  height: 4px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.schedule_content__head .tab_list .tab._selected:hover {
  background-color: #ffffff;
}
@media screen and (min-width: 768px) {
  .schedule_content__body {
    padding: 30px;
  }
}
@media screen and (max-width: 767px) {
  .schedule_content__body {
    padding: 30px 15px;
  }
}

.schedule_dep_area:not(:first-child) {
  margin-top: 4em;
}
.schedule_dep_area__head {
  padding-top: 1em;
  margin-bottom: 2em;
}
.schedule_dep_area__head h3:not(.cancel) {
  margin-top: 0;
  margin-bottom: 0.4em;
}
.schedule_dep_area__head h3:not(.cancel) .group_label {
  font-size: 0.938rem;
  padding: 0 clamp(1em, 2vw, 1.5em);
  height: 30px;
  border-radius: 15px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 1em;
}
.schedule_dep_area__head h3:not(.cancel) .group_label._general_outpatient {
  color: #b5526d;
  background-color: #f9f1f3;
}
.schedule_dep_area__head h3:not(.cancel) .group_label._specialty_outpatient {
  color: #355790;
  background-color: #f3f6fb;
}

@media screen and (min-width: 768px) {
  .h2_link_title {
    position: relative;
  }
  .h2_link_title h2 {
    padding-right: 140px;
  }
  .h2_link_title a {
    position: absolute;
    right: 0;
    bottom: 1em;
  }
}
@media screen and (max-width: 767px) {
  .h2_link_title a {
    float: right;
    margin-top: -1.4em;
    margin-bottom: 1.4em;
  }
}
@media screen and (max-width: 575px) {
  .h2_link_title a {
    margin-top: -0.5em;
    margin-bottom: 1.5em;
  }
}

.schedule_dep:not(:last-child) {
  margin-bottom: clamp(50px, 11%, 80px);
}
.schedule_dep__head {
  position: relative;
  margin-bottom: 1.5em;
}
@media screen and (min-width: 768px) {
  .schedule_dep__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.schedule_dep__head__l {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
@media screen and (max-width: 767px) {
  .schedule_dep__head__l {
    gap: 0.7em;
  }
}
@media screen and (max-width: 767px) {
  .schedule_dep__head__r {
    margin-top: 0.5em;
    text-align: right;
  }
}
.schedule_dep__head__r ._link {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: right;
  color: #111111;
  font-size: 1rem;
}
.schedule_dep__head__r ._link::after {
  content: "";
  flex-shrink: 0;
  display: inline-block;
  background: url("../img/icon/arrow/circle_blue_02.webp") 0 0 no-repeat transparent;
  background-size: 24px 24px;
  width: 24px;
  height: 24px;
  margin-left: 10px;
  transition: transform 0.2s;
}
.schedule_dep__head__r ._link:hover {
  text-decoration: underline;
}
.schedule_dep__head h5:not(.cancel) {
  margin-bottom: 0;
  margin-right: clamp(10px, 3vw, 30px);
}
.schedule_dep__head .group_label {
  font-size: 0.938rem;
  padding: 0 clamp(1em, 2vw, 1.5em);
  height: 30px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .schedule_dep__head .group_label {
    display: inline-flex;
  }
}
.schedule_dep__head .group_label._general_outpatient {
  color: #b5526d;
  background-color: #f9f1f3;
}
.schedule_dep__head .group_label._specialty_outpatient {
  color: #355790;
  background-color: #f3f6fb;
}
.schedule_dep__body > .no_data_text {
  padding-left: 3.7%;
}
@media screen and (min-width: 768px) {
  .schedule_dep__foot {
    margin-top: 30px;
  }
}
@media screen and (max-width: 767px) {
  .schedule_dep__foot {
    margin-top: 4%;
  }
}
@media screen and (min-width: 768px) {
  .schedule_dep__foot__link {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .schedule_dep__foot__link {
    margin-top: 4%;
    display: flex;
    justify-content: flex-end;
  }
}
.schedule_dep .no_data_text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

@media screen and (min-width: 768px) {
  .schedule_row_list > li:not(:last-child) {
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 767px) {
  .schedule_row_list > li:not(:last-child) {
    margin-bottom: 10px;
  }
}

.schedule_row {
  display: flex;
}
@media screen and (min-width: 768px) {
  .schedule_row {
    min-height: 80px;
  }
}
@media screen and (max-width: 767px) {
  .schedule_row {
    min-height: 80px;
  }
}
.schedule_row__head {
  flex-basis: 60px;
  flex-shrink: 0;
  flex-grow: 0;
  box-sizing: border-box;
  display: flex;
}
@media screen and (max-width: 767px) {
  .schedule_row__head {
    flex-basis: 40px;
  }
}
.schedule_row__body {
  display: flex;
  width: 100%;
  padding-left: 13px;
  box-sizing: border-box;
  position: relative;
  top: 0;
  left: 0;
}
@media screen and (max-width: 767px) {
  .schedule_row__body {
    padding-left: 7px;
  }
}
.schedule_row .term_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  border: 1px solid #dddddd;
  width: 100%;
  background-color: #f8f8f8;
}
.schedule_row .term_box .term {
  font-size: 1rem;
}
@media screen and (max-width: 767px) {
  .schedule_row .term_box .term {
    font-size: 0.875rem;
  }
}
.schedule_row .schedule_box {
  width: 100%;
  border-radius: 7px;
  border: 1px solid #dddddd;
  background-color: #ffffff;
  display: flex;
  box-sizing: border-box;
  padding: 5px 10px;
}
@media screen and (max-width: 767px) {
  .schedule_row .schedule_box {
    padding: 2.5%;
  }
}
.schedule_row .schedule_box > .no_data_text {
  padding-left: 2.4%;
}
.schedule_row .schedule_box > .ex {
  display: flex;
  padding-left: 2%;
  padding-right: 2%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.schedule_info_area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border: 1px solid #e0e7f4;
  background-color: #f3f7f9;
  border-radius: 7px;
  padding: clamp(17px, 2vw, 30px) 4.4%;
  box-sizing: border-box;
}

.schedule_person_list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: -5px;
}
.schedule_person_list > li {
  margin-top: 5px;
  margin-bottom: 5px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 5px;
  padding-right: 5px;
}
@media screen and (min-width: 1000px), print {
  .schedule_person_list > li {
    min-width: 33.33%;
  }
}
@media screen and (max-width: 999px) {
  .schedule_person_list > li {
    min-width: 0;
  }
}

.schedule_person {
  min-height: 70px;
  background-color: #ffffff;
  color: #111111;
  text-decoration: none;
  align-items: center;
  line-height: 1.3;
  transition: background-color 0.2s cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 6px;
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
}
@media screen and (min-width: 768px) {
  .schedule_person {
    padding: 5px 10px;
  }
}
@media screen and (max-width: 767px) {
  .schedule_person {
    padding: 5px;
  }
}
.schedule_person::before {
  content: "";
  display: block;
  background: url("../img/icon/schedule_male.webp") 0 0 no-repeat transparent;
  background-size: 60px 62px;
  width: 60px;
  height: 62px;
  margin-right: 10px;
  flex-basis: 46px;
  flex-shrink: 0;
  flex-grow: 0;
  background-position: center center;
  background-size: contain;
}
@media screen and (max-width: 767px) {
  .schedule_person::before {
    width: 40px;
    height: 40px;
    flex-basis: 40px;
  }
}
.schedule_person::after {
  content: "";
  display: block;
  background: url("../img/icon/arrow/schedule_male.webp") 0 0 no-repeat transparent;
  background-size: 24px 24px;
  width: 24px;
  height: 24px;
  margin-left: 10px;
  min-width: 24px;
  flex-basis: 24px;
}
.schedule_person._male::before {
  background-image: url("../img/icon/schedule_male.webp");
}
.schedule_person._male::after {
  background-image: url("../img/icon/arrow/schedule_male.webp");
}
.schedule_person._female::before {
  background-image: url("../img/icon/schedule_female.webp");
}
.schedule_person._female::after {
  background-image: url("../img/icon/arrow/schedule_female.webp");
}
.schedule_person .name {
  font-size: 1.125rem;
}
.schedule_person .option {
  font-size: 0.938rem;
  line-height: 1.3;
  margin-top: 0.2em;
}
.schedule_person .label {
  margin-top: 0.2em;
}
.schedule_person .label._appointment_only {
  font-size: 0.875rem;
  background-color: #de617b;
  color: #ffffff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 0.3em 0.8em;
  min-width: 60px;
  min-height: 24px;
  line-height: 1;
  box-sizing: border-box;
}
.schedule_person._noperson::before, .schedule_person._noperson::after {
  display: none;
}

a.schedule_person:hover {
  background: rgba(183, 211, 208, 0.2);
}
a.schedule_person._disabled {
  pointer-events: none;
}
a.schedule_person._disabled::after {
  display: none;
}
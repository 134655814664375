@use "import/var";
@use "import/mixin_common" as common;

.dep_pagelink_area {
	width: 100%;

	> ul {
		@media #{var.$ipc} {
			display: flex;
			justify-content: space-between;
			gap: 20px;
			flex-wrap: wrap;

			> li {
				display: flex;
				width: calc(50% - 10px);
			}
		}

		@media #{var.$isp} {
			> li {
				margin-bottom: 2em;
			}
		}
	}
}

.dep_pagelink {
	width: 100%;
	font-size: var.$fontsize16;
	text-decoration: none;
	color: var.palette("button", "default");
	background-color: var.palette("button", "bg");
	border: 1px solid var.palette("button", "border");
	border-radius: 35px;
	line-height: 1.3;
	box-sizing: border-box;
	position: relative;
	transition: all 0.2s var.$easeOutQuint;
	height: 70px;
	min-width: 220px;
	overflow: hidden;

	&__head {
		box-sizing: border-box;
		display: flex;
		justify-content: space-between;
		height: 70px;
		padding-left: 30px;

		&__l {
			padding: 23px 0;
		}

		&__r {
			cursor: pointer;
			// background-color: rgba(#ff0000, 50%);
			width: 90px;
			display: flex;
			justify-content: center;
			align-items: center;
			color: #439574;
			position: relative;

			&::before {
				content: "";
				display: block;
				background-color: var.palette("button", "border");
				position: absolute;
				left: 0;
				height: 40px;
				width: 1px;
			}

			.toggle {
				&::before {
					content: "＋";
					font-size: 24px;
				}

				&._open {
					&::before {
						content: "−";
						display: block;
						font-size: 30px;
					}
				}
			}
		}

		.cb {
			width: 0;
			height: 0;
			overflow: hidden;
		}

		&::after {
			content: "";
			display: block;
			background-color: var.palette("button", "border");
			position: absolute;
			top: 71px;
			height: 1px;
			width: 100%;
		}
	}

	&__body {
		overflow: auto;
		will-change: transform;
		top: 70px;
		position: absolute;
		width: 100%;
		height: 260px;
		box-sizing: border-box;
		padding-top: 20px;
	}

	a {
		text-decoration: none;
		color: var.palette("color", "default");
		font-size: var.$fontsize16;
		padding: 0.8em 30px 0.8em 70px;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		white-space: nowrap;
		overflow: hidden;

		&::before {
			@include common.bg-block("../img/icon/arrow/circle_green_24.webp", 24px, 24px);

			transform: rotate(90deg);
			position: absolute;
			left: 30px;
		}

		&:hover {
			color: var.palette("link", "hover");
		}
	}
}

@use "import/var";
@use "import/mixin_common" as common;

.h2_link_title {
	@media #{var.$ipc} {
		position: relative;

		h2 {
			padding-right: 140px;
		}

		a {
			position: absolute;
			right: 0;
			bottom: 1em;
		}
	}

	@media #{var.$isp} {
		a {
			float: right;
			margin-top: -1.4em;
			margin-bottom: 1.4em;
		}
	}

	@media #{var.$xs} {
		a {
			margin-top: -0.5em;
			margin-bottom: 1.5em;
		}
	}
}

.schedule_dep {
	&:not(:last-child) {
		margin-bottom: clamp(50px, 11%, 80px);
	}

	&__head {
		position: relative;
		margin-bottom: 1.5em;

		@media #{var.$ipc} {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		&__l {
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			@media #{var.$isp} {
				gap: 0.7em;
			}
		}

		&__r {
			@media #{var.$isp} {
				margin-top: 0.5em;
				text-align: right;
			}

			._link {
				text-decoration: none;
				display: flex;
				align-items: center;
				justify-content: right;
				color: #111111;
				font-size: var.$fontsize16;

				&::after {
					content: "";
					flex-shrink: 0;
					display: inline-block;
					background: url("../img/icon/arrow/circle_blue_02.webp") 0 0 no-repeat transparent;
					background-size: 24px 24px;
					width: 24px;
					height: 24px;
					margin-left: 10px;
					transition: transform 0.2s;
				}

				&:hover {
					text-decoration: underline;
				}
			}
		}

		h5:not(.cancel) {
			margin-bottom: 0;
			margin-right: clamp(10px, 3vw, 30px);
		}

		.group_label {
			font-size: var.$fontsize15;
			padding: 0 clamp(1em, 2vw, 1.5em);
			height: 30px;
			border-radius: 15px;
			display: flex;
			justify-content: center;
			align-items: center;

			@media #{var.$isp} {
				display: inline-flex;
			}

			&._general_outpatient {
				color: #b5526d;
				background-color: #f9f1f3;
			}

			&._specialty_outpatient {
				color: #355790;
				background-color: #f3f6fb;
			}
		}
	}

	&__body {
		> .no_data_text {
			padding-left: 3.7%;
		}
	}

	&__foot {
		@media #{var.$ipc} {
			margin-top: 30px;
		}

		@media #{var.$isp} {
			margin-top: 4%;
		}

		&__link {
			@media #{var.$ipc} {
				display: none;
			}

			@media #{var.$isp} {
				margin-top: 4%;
				display: flex;
				justify-content: flex-end;
			}
		}
	}

	.no_data_text {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
	}
}

.schedule_row_list {
	> li {
		&:not(:last-child) {
			@media #{var.$ipc} {
				margin-bottom: 15px;
			}

			@media #{var.$isp} {
				margin-bottom: 10px;
			}
		}
	}
}

.schedule_row {
	display: flex;

	@media #{var.$ipc} {
		min-height: 80px;
	}

	@media #{var.$isp} {
		min-height: 80px;
	}

	&__head {
		flex-basis: 60px;
		flex-shrink: 0;
		flex-grow: 0;
		box-sizing: border-box;
		display: flex;

		@media #{var.$isp} {
			flex-basis: 40px;
		}
	}

	&__body {
		display: flex;
		width: 100%;
		padding-left: 13px;
		box-sizing: border-box;
		position: relative;
		top: 0;
		left: 0;

		@media #{var.$isp} {
			padding-left: 7px;
		}
	}

	.term_box {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		border-radius: 7px;
		border: 1px solid #dddddd;
		width: 100%;
		background-color: #f8f8f8;

		.term {
			font-size: var.$fontsize16;

			@media #{var.$isp} {
				font-size: var.$fontsize14;
			}
		}
	}

	.schedule_box {
		width: 100%;
		border-radius: 7px;
		border: 1px solid #dddddd;
		background-color: #ffffff;
		display: flex;
		box-sizing: border-box;
		padding: 5px 10px;

		@media #{var.$isp} {
			padding: 2.5%;
		}

		> .no_data_text {
			padding-left: 2.4%;
		}

		> .ex {
			display: flex;
			padding-left: 2%;
			padding-right: 2%;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
		}
	}
}

.schedule_info_area {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	border: 1px solid #e0e7f4;
	background-color: #f3f7f9;
	border-radius: 7px;
	padding: clamp(17px, 2vw, 30px) 4.4%;
	box-sizing: border-box;
}

@use "import/var";
@use "import/mixin_common" as common;

.schedule_dep_area {
	&:not(:first-child) {
		margin-top: 4em;
	}

	&__head {
		padding-top: 1em;
		margin-bottom: 2em;

		h3:not(.cancel) {
			margin-top: 0;
			margin-bottom: 0.4em;

			.group_label {
				font-size: var.$fontsize15;
				padding: 0 clamp(1em, 2vw, 1.5em);
				height: 30px;
				border-radius: 15px;
				display: inline-flex;
				justify-content: center;
				align-items: center;
				margin-left: 1em;

				&._general_outpatient {
					color: #b5526d;
					background-color: #f9f1f3;
				}

				&._specialty_outpatient {
					color: #355790;
					background-color: #f3f6fb;
				}
			}
		}
	}
}
